import React from "react"

import Layout from "../components/layout"
import AboutContent from "../components/aboutContent"

const AboutPage = ({ location }) => (
  <Layout location={location}>
    <AboutContent />
  </Layout>
)

export default AboutPage
